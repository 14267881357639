/* .css-axafay-MuiDataGrid-virtualScroller{
    height: 35px;
} */
.rdw-editor-toolbar{
    margin-bottom: 0px !important;
}
.rdw-editor-main{
    border: 1px solid #F1F1F1;
    padding: 0 5px;
    min-height: 120px;
    max-height: 150px;
}
.MuiDataGrid-columnHeaderTitleContainerContent{
    font-weight: 600;
}
.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-inline-end: 8px;
  }
  
  .ant-upload-rtl.upload-list-inline .ant-upload-list-item {
    float: right;
  }