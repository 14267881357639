@keyframes animation-dots {
  0% {
    left: -150px;
    opacity: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  80% {
    left: 100px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 200px;
  }
}